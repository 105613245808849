.App {
  text-align: center;
  background-color: #9370db;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: larger;
  color: white;
}

header {
  font-size: xx-large;
  padding: 30px;
}

.sample-generator {
  width: 25%;
}

.sample-generator input {
  width: 100%;
  border: none;
  border-bottom: 2px solid white;
  background-color: mediumpurple;
  color: white;
  transition: 0.3s all;
  padding: 12px 20px;
  margin: 10px 0 20px 0;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  /*font-size: calc(5px + 1vmin);*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  width: 100%;
  margin: 8px 0;
  transition-duration: 0.4s;
  border: none;
  border-radius: 6px;
  background-color: lightpink;
  color: rebeccapurple;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: inherit;
}

button:hover {
  background-color: pink;
  color: white;
}

/* Medium screens */
@media all and (max-width: 900px) {
  .sample-generator {
    width: 80%;
    font-size: medium;
  }
}